@font-face {
  font-family: Maersk-Bold;
  src: url('fonts/MaerskText-Bold.woff2');
}

@font-face {
  font-family: Maersk-Light;
  src: url('fonts/MaerskText-Light.woff2');
}

@font-face {
  font-family: Maersk-Regular;
  src: url('fonts/MaerskText-Regular.woff2');
}

body {
  font-family: 'Maersk-Regular' !important;
  margin-top: 80px;
}

/* nav {
  box-sizing: content-box;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(197, 197, 197, 0.5);
} 

a[target='_blank']:after {
  content: '\1F5D7';
}


  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

/* 
  Style for default content block
*/
.contentTitle {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

img.apmt-logo {
  width: 200px;
}

#root {
  margin-top: 90px;
}

@media screen and (max-width:1024px) {
  #root {
    margin-top: 60px;
  }
}