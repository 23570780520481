  div.vacancies-section {
    margin: 0 30px;
  }
  div.vacancies-navigator {
    font-size: 14px;
    background: #f7f7f7;
    padding: 5px 60px 8px;
    margin: 0 -15px;
  }
  
  div.vacancies-title {
    font-size: 52px;
    font-family: 'Maersk-Light';
    margin: 35px 0 50px 15px;
    color: #3c3c46;
    line-height: 50px;
  }
  
  div.vacancies-result > div.vacancies-container {
  display: flex;
  flex-wrap: wrap;
  margin:0 auto;
  }
  
  div.vacancies-result > div.vacancies-total {
    margin: 0 15px 20px;
    color:#737373;
  }
  
  div.vacancies-result > div.vacancies-container > div.vacancies-card {
    width: calc((100% / 3) - 25px);
    margin: 0 12.5px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(197, 197, 197, 0.5);
    padding: 32px 24px;
    color:#737373;
    position: relative;
  }
  
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-title {
    font-size: 24px;
    font-family: 'Maersk-Light';
    color:#3c3c46;
  }
  
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-date,
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-job-title,
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-location {
    font-size: 14px;
  }
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-job-title {
    color:#454545;
  }
  
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-more {
    font-size: 16px;
    position: absolute;
    bottom: 32px;
  }
  
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-location {
    margin-bottom: 50px;
  }
  
  div.vacancies-result > div.vacancies-container > div.vacancies-card > .card-location {
    color: #ff6441;
    font-family: 'Maersk-Bold';
  }

  div.vacancies-result > div.no-result {
    color:#e21f2d;
    margin:0 15px 20px;
  }

  ::-webkit-input-placeholder {
  color: #a3adbc;
  }

  .pagination-action {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid #ff6441;
    background-color: #ffefec;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .pagination-action.action-next {
    background-image: url('../../assets/images/svgs/next.svg');
  }

  .pagination-action.action-prev {
    background-image: url('../../assets/images/svgs/prev.svg');
  }

  .pagination-action:hover {
    background-color: #ff6441;
  }
  
  .action-next:hover {
    background-image: url('../../assets/images/svgs/next-reverse.svg');
  }

  .action-prev:hover {
    background-image: url('../../assets/images/svgs/prev-reverse.svg');
  }

  .vacancies-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 40px 15px;
  }

  .vacancies-filters {
    background: #ffffff;
    box-shadow: 0px 2px 15px rgb(197 197 197 / 50%);
    width: calc(100% - 25px);
    margin: 0 12.5px 20px;
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
  }

  .vacancies-filters > div.filter {
    position: relative;
    display: inline-block;
    width: 30%;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  
  .vacancies-filters label {
    position: absolute;
    display: inline-block;
    color:#737373;
    font-size: 14px;
  }

  .search-box .search-input {
    background-image: url('../../assets/images/svgs/search.svg');
    background-position: calc(100% - 15px) calc(100% - 12px);
    background-repeat: no-repeat;
  }

  .pagination-input {
    padding: 0;    
    line-height: 25px;
    width: 32px !important;
    height: 32px !important;
    text-align: center;
  }
  
  .vacancies-filters > .filter {
    flex-basis: calc(33.33% - 10px);
  }
  
  .vacancies-filters > .filter.search-box {
    flex-basis: calc(66.66% - 10px);
  }

  .vacancies-filters > div > input.search-input,
  .vacancies-filters > div > .selected-value {
    line-height: 32px;
    width: 100%;
    text-align: left;
    padding: 0 8px;
    padding-right: 60px;
    height: 48px;
    margin-top:22px;
    position: relative;
    align-items: center;
    display: flex;
  }

  .vacancies-filters > div > input.search-input, 
  .vacancies-pagination input {
    border: 1px solid #7a8591;
    background-color: #fafbfc;
    color: #3c3c46;
    outline: 0;
    border-radius: 4px;
  }

  .vacancies-filters > div > input.search-input:focus, 
  .vacancies-pagination input:focus {
    border-color: #3c3c46;
  }

  .vacancies-filters .clear-filters {
    width: auto;
  }

  .vacancies-filters .clear-filters button {
    background-color: #fff;
    border: 0;
    text-decoration: none;
    background-image: url('../../assets/images/svgs/refresh.svg');
    background-repeat: no-repeat;
    height: 35px;
    color:#ff6441;
    background-position: 10px;
    padding-left: 50px;
    margin-top: 27px;
  }

  .vacancies-pagination input {
    font-size: 14px;
  }

  .content-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(158 158 158 / 0.1);
    z-index: 1;
  }

  .content-loader div {
    top: calc(50% - 4px);
    width: 110px;
    height: 8px;
    position: relative;
    margin-left: calc(50% - 55px);
  }

  .content-loader div::before{
    content: "";
    display: block;
    height: 100%;
    background: #ff6441;
    position: absolute;
    width: 0;
    animation-duration: 3.5s;
    animation-name: loader;
    animation-iteration-count: infinite;
  }

  .is-hidden {
    display: none;
  }

  @keyframes loader {
    0% {
        left: 0;
        width: 0;
    }

    11% {
        left: auto;
        right: auto;
        width: 100%;
        background: #ff6441;
    }
    
    12% {
        right: 0;
    }

    24% {
        right: 0;
        width: 0;
        background: #3c3c46;
    }
    25% {
        right: auto;
    }

    37% {
        right: auto;
        width: 100%;
        background: #3c3c46;
    }
    38% {
        right: 0;
    }

    50% {
        right: 0;
        width: 0;
        background: #0a6e80;
    }
    51% {
        right: auto;
    }

    63% {
        right: auto;
        width: 100%;
        background: #0a6e80;
    }
    64% {
        right: 0;
    }

    76% {
        right: 0;
        width: 0;
        background: #eeaf30;
    }
    77% {
        right: auto;
    }

    89% {
        right: auto;
        width: 100%;
        background: #eeaf30;
    }
    90% {
        right: 0;
    }

    99% {
        right: 0;
        width: 0;
        background:  #ff6441;
    }
    100% {
        right: auto;
    }
  }
  
  .vacancies-card > a {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }


  .vacancies-card > a > span {
    position: absolute;
    bottom: 30px;
    left: 16px;
    color: #ff6441;
    font-family: 'Maersk-Bold';
    padding: 4px 8px;
  }

  .vacancies-card > a:hover > span {
    text-decoration: underline;
  }

  .vacancies-card > a:focus > span {
    background: #ffefec;
    outline: none;
    padding: 4px 8px;
  }

  @media screen and (max-width:1024px) {
    div.vacancies-result > div.vacancies-container > div.vacancies-card {
    width: calc((100% / 2) - 25px);
    }
    div.vacancies-section {
      margin: 0 10px;
    }
    div.vacancies-navigator {
      padding: 5px 40px 8px;
    }
  }

  @media screen and (max-width:768px) {
    div.vacancies-result > div.vacancies-container > div.vacancies-card {
      width: 100%;
    }
    
    div.vacancies-section {
      margin: 0 -5px;
      overflow-x: hidden;
    }
    div.vacancies-navigator {
      padding: 5px 25px 8px;
    }
    .vacancies-filters .clear-filters button {
      margin-top: 10px;
      margin-left: -8px;
    }
    .vacancies-filters > .filter.search-box, .vacancies-filters > div.filter {
      flex-basis: 100%;
      margin: 0;
    }
  }